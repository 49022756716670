body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0.5em;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgb(250, 250, 250);
}
 
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.signalsMain .post {
  font-size: 16px;
  font-weight: 500;
  color: rgb(114, 114, 114);
  margin-bottom: 10px;
  white-space: pre-line;
  /* max-height: 72px;
  overflow: hidden; */
}

 .showMoreB {
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  margin: 20px 0;
}

.signalsMain .time {
  font-size: 14px;
  font-weight: 400;
  color: rgb(110, 110, 110);
  /* margin-top: 10px; */
  margin-left: auto;
}
.signalsMain .addBtn {
  cursor: pointer;
  background: gray; 
  display: flex;
  align-items: center;
  font-size: 37px;
  color: white;
  border: none;
  padding: 8px;
  height: 40px;
  border-radius: 50%;
  margin-left: auto; 
  position: relative;
  top: 49px;
  right: 10px;
}
.signalsMain .imgDiv {
  min-width: 100%;
  /* min-height: 400px; */
  max-height: 500px;
  border-radius: 7px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

}

.signalsMain img {
  max-width: 100%;
  width: auto;
}
.signalsMain .editIco img{
  width: 30px;
  cursor: pointer;
}
.signalsMain .cross img{
  width: 33px;
  margin-left: 10px;
  cursor: pointer;
}
.my-1 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.fileUpload-modal {
  margin-top: 20px;
  height: 190px;
}

.fileUpload-modal label {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  border: 2px dashed;
  border-color: #adadad;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.fileUpload-modal label img {
  width: 30px;
}

.fileUpload-modal label .img_sel {
  max-width: 100%;
  width: auto;
  max-height: 100%;
}

.fileUpload-modal label input {
  display: none;
}
.signalsMain {
  max-width: 680px;
  margin: auto;
}
.signalsMain .signalCard {
  background: #f8f8f8;
  border-radius: 20px; 
  padding: 15px 25px; 
  margin-bottom: 30px
}

.signalsMain .bottom-cont-posts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.signalsMain .bottom-cont-posts .icons img {
  width: 25px;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
}
.dateTool {
  position: relative;
  cursor: alias;
}

.dateTool:hover .fullDate {
  display: block;

}

.dateTool .fullDate {
  position: absolute;
  padding: 7px;
  border-radius: 6px;
  background-color: black;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  bottom: -45px;
  color: white;
  font-size: 12px;
  display: none;
}

.text-center {
  text-align: center;
}

.noStakerM .btnStake {
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  background-color: #05B4EF;
  border-radius: 16px;
  color: white;
  text-decoration: none;
  padding: 12px 30px;
  min-width: 132px;
  display: inline-block;
  text-align: center;
}
.noStakerM .btnStake:hover {
  background-color: #1976d2;
}
.noStakerM p {
  color: rgb(150, 150, 150);
  font-size: 17px;
}
.mt-4 {
  margin-bottom: 1.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.d-flex {
  display: flex;
}
.ai-center {
  align-items: center;
}
.css-3lxtmb-MuiModal-root-MuiDrawer-root {
  z-index: 22222222;
}
.css-3lxtmb-MuiModal-root-MuiDrawer-root div{
  z-index: 22222222;
}
.locktupEmailMain {
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (max-width:992px) {
  .locktupEmailMain {
    flex-direction: column;
  }
}
@media (max-width:576px) {
  .css-1hty0hr {
    padding-left: 15px;
    padding-right: 15px;
  }
  .css-1up7e5e-MuiButtonBase-root-MuiButton-root {
    padding: 8px 24px !important;
  }
  .noStakerM .btnStake {
    padding: 8px 24px !important;
  }
}

