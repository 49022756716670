.seekoLoader {
    position: fixed;
    top: 0;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    width: 100vw;
     left: 0;
    height: 100vh;
    background: rgba(0,0,0,.2);
    z-index: 1111;
 }

   
    .loader  {
        width: 100px;
        animation: beat .4s infinite alternate;
    }
        /* Heart beat animation */
        @keyframes beat{
          to { transform: scale(1.3); 
          }
      }