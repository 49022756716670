.iconsModal1{
    width: 30%;
    height: 30%;
    padding: 20px;
    margin-left: 30px !important;
    box-shadow: 0 3px 10px rgba(121, 121, 121, 0.2);
    border-radius: 20px;
    transition: all 0.3s;

}
.iconsModal2{
    margin-right: 15px;
    margin-left: 15px;
    width: 30%;
    height: 30%;
    padding: 20px;
    box-shadow: 0 3px 10px rgba(121, 121, 121, 0.2);
    border-radius: 20px;
    transition: all 0.3s;
}
.iconsModal1:hover {
    cursor: pointer;
    background-color: rgb(240, 240, 240);
    border-radius: 20px;
}
.iconsModal3{
    width: 30%;
    height: 30%;
    padding: 20px;
    margin-right: 30px !important;
    box-shadow: 0 3px 10px rgba(121, 121, 121, 0.2);
    border-radius: 20px;
    margin-right: 30px !important;
    transition: all 0.3s;
}
.iconsModal3:hover {
    cursor: pointer;
    background-color: rgb(240, 240, 240);
    border-radius: 20px;
}
.iconsModal2:hover {
    cursor: pointer;
    background-color: rgb(240, 240, 240);
    border-radius: 20px;
}
    .listItemText{
      font-size:'0em',
    }
